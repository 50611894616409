import React, { useEffect, useState } from "react";
import Loader from "../../Components/loader";
import ReactToPdf from "react-to-pdf";
import ResumeData from "../../Assets/data/resume.json";
import Projects from "../../Assets/data/projects.json";

export default function Resume2(props) {
  const [loader, setLoader] = useState(true);

  setTimeout(() => setLoader(false), 3000);

  useEffect(() => {}, []);

  if (loader) {
    return <Loader isloading={loader} />;
  }

  const downloadFile = (file_path, target = "_self") => {
    if (!file_path) {
      return;
    }
    var a = document.createElement("A");
    a["href"] = file_path;
    a["download"] = file_path.substr(file_path.lastIndexOf("/") + 1);
    a["target"] = target;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const { basic, work, education, awards, skills } = ResumeData;

  return (
    <>
      <main id="mainWrapper" className="resume">
        <div
          id="pageBody"
          style={{
            alignItems: "start",
            fontFamily: "Roboto,sans-serif",
            letterSpacing: 1,
          }}
        >
          <div id="pageContent">
            <div className="row">
              <div className="col-xs-0 col-lg-2" />
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-8">
                <ReactToPdf
                  filename={`invoice.pdf`}
                  options={{
                    unit: "mm",
                    format: [297, 230],
                  }}
                >
                  {({ toPdf, targetRef }) => (
                    <>
                      <div
                        className="card bg-card"
                        style={{
                          minHeight: "100vh",
                          height: "1122px",
                          border: 0,
                          flexDirection: "row",
                          padding: 10,
                        }}
                        id={"print-resume"}
                        ref={targetRef}
                      >
                        <div style={styles.pagePart1} />
                        <div style={styles.pagePart2}>
                          <hr
                            style={{
                              border: "1px var(--bg-dark-color) solid",
                              margin: 0,
                              width: false ? "95%" : "100%",
                            }}
                          />
                          <section style={styles.introSection}>
                            <div
                              style={{
                                width: "35%",
                                borderRight: "1px var(--bg-dark-color) solid",
                              }}
                            >
                              <h1 style={styles.myName}>{basic.fullName}</h1>
                              <h5 style={styles.designation}>
                                {basic.designation}
                              </h5>
                            </div>
                            <div
                              style={{
                                width: "40%",
                                // borderLeft: "1px var(--bg-dark-color) solid",
                              }}
                            >
                              <div style={styles.contactInfo}>
                                <div style={styles.sectionIcon}>
                                  <i className={`fas fa-phone-alt fa-xs`} />
                                </div>
                                <div style={styles.contactText}>
                                  {basic.contactNo}
                                </div>
                              </div>
                              <div style={styles.contactInfo}>
                                <div style={styles.sectionIcon}>
                                  <i
                                    className={`fas fa-map-marker-alt fa-xs`}
                                  />
                                </div>
                                <div style={styles.contactText}>
                                  {basic.address}
                                </div>
                              </div>
                              <div style={styles.contactInfo}>
                                <div style={styles.sectionIcon}>
                                  <i className={`fas fa-globe fa-xs`} />
                                </div>
                                <div style={styles.contactText}>
                                  {basic.website}
                                </div>
                              </div>
                              <div style={styles.contactInfo}>
                                <div style={styles.sectionIcon}>
                                  <i className={`fas fa-at fa-xs`} />
                                </div>
                                <div style={styles.contactText}>
                                  {basic.email}
                                </div>
                              </div>
                            </div>
                          </section>
                          <hr
                            style={{
                              border: "1px var(--bg-dark-color) solid",
                              margin: 0,
                              width: false ? "95%" : "100%",
                            }}
                          />
                          <section style={styles.mainSection}>
                            <div
                              style={{
                                width: "35%",
                                borderRight: "1px var(--bg-dark-color) solid",
                                height: "100%",
                              }}
                            >
                              <section
                                style={{ marginRight: 20, marginBottom: 50 }}
                              >
                                <h2 style={styles.sectionTitle}>PROFILE</h2>
                                <hr
                                  style={{
                                    border: "1.5px var(--bg-dark-color) solid",
                                    margin: 0,
                                    marginBottom: 20,
                                    width: "50px",
                                  }}
                                />
                                <div style={{ textAlign: "justify" }}>
                                  Throughout my 3+ year career as a Software
                                  Engineer, I have focused on developing
                                  scalable and well-documented code. I enjoy
                                  working collaboratively but can also run with
                                  projects independently.
                                </div>
                              </section>
                              <section
                                style={{ marginRight: 20, marginBottom: 50 }}
                              >
                                <h2 style={styles.sectionTitle}>Education</h2>
                                <hr
                                  style={{
                                    border: "1.5px var(--bg-dark-color) solid",
                                    margin: 0,
                                    marginBottom: 20,
                                    width: "50px",
                                  }}
                                />
                                {education
                                  .filter((i) => !!i.isShown)
                                  .sort((a, b) => b.id - a.id)
                                  .map((item) => (
                                    <div style={{ marginBottom: 25 }}>
                                      <h6>{item.degree}</h6>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        <div>
                                          <i
                                            className={`fas fa-briefcase fa-xs`}
                                          />
                                        </div>
                                        <span
                                          style={{
                                            marginLeft: 10,
                                            fontSize: ".85rem",
                                          }}
                                        >
                                          {item.keyPoints[0]}
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        <div>
                                          <i className={`fas fa-clock fa-xs`} />
                                        </div>
                                        <span
                                          style={{
                                            marginLeft: 10,
                                            fontSize: ".85rem",
                                          }}
                                        >
                                          {item.exp}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                              </section>
                            </div>
                            <div
                              style={{
                                width: "65%",
                                paddingLeft: 20,
                              }}
                            >
                              <section
                                style={{ marginRight: 30, marginBottom: 50 }}
                              >
                                <h2 style={styles.sectionTitle}>
                                  Work Experience
                                </h2>
                                <hr
                                  style={{
                                    border: "1.5px var(--bg-dark-color) solid",
                                    margin: 0,
                                    marginBottom: 20,
                                    width: "50px",
                                  }}
                                />
                                {work
                                  .sort((a, b) => b.id - a.id)
                                  .map((item) => (
                                    <div style={{ marginBottom: 25 }}>
                                      <h6 style={{ marginBottom: 0 }}>
                                        {item.company}
                                      </h6>
                                      <label>
                                        {item.designation} {"//"} {item.exp}
                                      </label>
                                      {item.keyPoints.length &&
                                        item.keyPoints.map((point) => (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <div>
                                              <i
                                                className={`fas fa-circle fa-xs`}
                                              />
                                            </div>
                                            <span
                                              style={{
                                                marginLeft: 10,
                                              }}
                                            >
                                              {point}
                                            </span>
                                          </div>
                                        ))}
                                    </div>
                                  ))}
                              </section>
                            </div>
                          </section>
                        </div>
                      </div>
                      {false && (
                        <button
                          style={{
                            position: "absolute",
                            top: 10,
                            right: 20,
                            border: 0,
                            borderRadius: 4,
                            backgroundColor: "var(--primary-color)",
                            padding: 5,
                            width: 100,
                          }}
                          onClick={toPdf}
                        >
                          Print
                        </button>
                      )}
                    </>
                  )}
                </ReactToPdf>
              </div>
              <div className="col-xs-0 col-lg-2" />
            </div>
            <div className="row">
              <div className="col-xs-0 col-lg-2" />
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <ReactToPdf
                  filename={`invoice.pdf`}
                  options={{
                    unit: "mm",
                    format: [297, 230],
                  }}
                >
                  {({ toPdf, targetRef }) => (
                    <>
                      <div
                        className="card bg-card"
                        style={{
                          minHeight: "100vh",
                          height: "1122px",
                          border: 0,
                          flexDirection: "row",
                          padding: 10,
                        }}
                        id={"print-resume"}
                        ref={targetRef}
                      >
                        <div style={styles.pagePart1} />
                        <div style={styles.pagePart2}>
                          <section style={styles.mainSection}>
                            <div
                              style={{
                                width: "35%",
                                borderRight: "1px var(--bg-dark-color) solid",
                                height: "100%",
                              }}
                            >
                              <section
                                style={{ marginRight: 20, marginBottom: 50 }}
                              >
                                <h2 style={styles.sectionTitle}>
                                  Achievements & Awards
                                </h2>
                                <hr
                                  style={{
                                    border: "1.5px var(--bg-dark-color) solid",
                                    margin: 0,
                                    marginBottom: 20,
                                    width: "50px",
                                  }}
                                />
                                {awards
                                  .sort((a, b) => b.id - a.id)
                                  .slice(0, -1)
                                  .map((item) => (
                                    <div style={{ marginBottom: 25 }}>
                                      <h6 style={{ marginBottom: 0 }}>
                                        {item.degree}
                                      </h6>
                                      <label>{item.exp}</label>
                                      {item.keyPoints.length &&
                                        item.keyPoints.map((point) => (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <div>
                                              <i
                                                className={`fas fa-circle fa-xs`}
                                              />
                                            </div>
                                            <span
                                              style={{
                                                marginLeft: 10,
                                              }}
                                            >
                                              {point}
                                            </span>
                                          </div>
                                        ))}
                                    </div>
                                  ))}
                              </section>
                              <section
                                style={{ marginRight: 20, marginBottom: 50 }}
                              >
                                <h2 style={styles.sectionTitle}>Skills</h2>
                                <hr
                                  style={{
                                    border: "1.5px var(--bg-dark-color) solid",
                                    margin: 0,
                                    marginBottom: 20,
                                    width: "50px",
                                  }}
                                />
                                {skills.map((item) => (
                                  <div style={{ marginBottom: 25 }}>
                                    <h6>{item.name}</h6>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {item.technologies.map((tech) => (
                                        <button
                                          style={{
                                            borderRadius: 4,
                                            border:
                                              "1.5px var(--bg-dark-color) solid",
                                            backgroundColor: "#fff",
                                            padding: 5,
                                            margin: 5,
                                          }}
                                        >
                                          {tech}
                                        </button>
                                      ))}
                                    </div>
                                  </div>
                                ))}
                              </section>
                            </div>
                            <div
                              style={{
                                width: "65%",
                                paddingLeft: 20,
                              }}
                            >
                              <section
                                style={{ marginRight: 30, marginBottom: 50 }}
                              >
                                <h2 style={styles.sectionTitle}>Projects</h2>
                                <hr
                                  style={{
                                    border: "1.5px var(--bg-dark-color) solid",
                                    margin: 0,
                                    marginBottom: 20,
                                    width: "50px",
                                  }}
                                />
                                {Projects.filter((i) => !!i.status)
                                  .sort((a, b) => b.ProjectId - a.ProjectId)
                                  .map((item) => (
                                    <div style={{ marginBottom: 25 }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <h6 style={{ marginBottom: 0 }}>
                                          {item.ProjectName}
                                        </h6>
                                        <h6 style={{ marginBottom: 0 }}>
                                          {item.year}
                                        </h6>
                                      </div>
                                      <label>{item.Description}</label>
                                      <div>
                                        <b>{"Tech Stack: "}</b>
                                      </div>
                                      <div>{item.stackList.join(", ")}</div>
                                    </div>
                                  ))}
                              </section>
                            </div>
                          </section>
                        </div>
                      </div>
                      {false && (
                        <button
                          style={{
                            position: "absolute",
                            top: 10,
                            right: 20,
                            border: 0,
                            borderRadius: 4,
                            backgroundColor: "var(--primary-color)",
                            padding: 5,
                            width: 100,
                          }}
                          onAbort
                          onClick={toPdf}
                        >
                          Print
                        </button>
                      )}
                    </>
                  )}
                </ReactToPdf>
              </div>
              <div className="col-xs-0 col-lg-2" />
            </div>
          </div>
        </div>
      </main>
      <button
        style={{
          position: "absolute",
          top: 10,
          right: 20,
          border: 0,
          borderRadius: 4,
          backgroundColor: "var(--primary-color)",
          padding: 5,
          width: 100,
        }}
        onClick={(e) => {
          downloadFile('/resume.pdf');
        }}
      >
        Download
      </button>
    </>
  );
}

const styles = {
  pagePart1: {
    backgroundColor: "var(--bg-dark-color)",
    // padding: 50,
    minHeight: "100vh",
    width: "5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  pagePart2: {
    minHeight: "100vh",
    padding: "30px",
    paddingLeft: "20px",
    width: "95%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  introSection: {
    height: 200,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // borderTop: "2px var(--bg-dark-color) solid",
    // borderBottom: "2px var(--bg-dark-color) solid",
    width: "100%",
  },
  mainSection: {
    marginTop: 50,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
  },
  sectionTitle: {
    color: "var(--bg-dark-color)",
    fontWeight: "bold",
    border: "2px var(--bg-dark-color) soild",
    marginBottom: 0,
  },
  contactInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "10%",
    marginBottom: 10,
  },
  contactText: {
    fontFamily: "Roboto,sans-serif",
    fontWeight: "normal",
  },
  part3: {
    minHeight: "100vh",
    padding: "30px",
    paddingLeft: "20px",
    width: "70%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  designation: {
    // fontFamily: "Segoe UI",
    fontWeight: "normal",
    letterSpacing: 1,
    textTransform: "uppercase",
  },
  myName: {
    letterSpacing: 2,
    color: "var(--bg-dark-color)",
  },
  sectionIcon: {
    backgroundColor: "var(--bg-dark-color)",
    textAlign: "center",
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 15,
  },
};
