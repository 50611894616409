import React, { useEffect, useState } from "react";
import "./about.css";
import Loader from "../../Components/loader";
import ResumeData from "../../Assets/data/resume.json";

const About = (props) => {
  const [loader, setLoader] = useState(true);
  const [currentCompany, setCurrentCompany] = useState({});

  setTimeout(() => setLoader(false), 3000);

  useEffect(() => {
    const { work } = ResumeData;
    setCurrentCompany(work.sort((a, b) => b.id - a.id)[0]);
  }, []);

  if (loader) {
    return <Loader isloading={loader} />;
  }

  return (
    <main id="mainWrapper" className="about">
      <div id="pageHead">
        <div className="page-header"></div>
      </div>
      <div id="pageBody">
        <div id="pageContent">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 align-items-center my-mobile-image">
              <div className="align-items-center">
                <img
                  className="img-fluid rounded-100 img-responsive mx-auto d-block"
                  src={"assets/images/my-image-min.jpg"}
                  alt="Ankur Sanghvi"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 align-items-center mt-5">
              <div className="col-sm-12">
                <h1 className="h1 head">About Me</h1>
              </div>
              <div className="col-sm-12">
                <p className="desc mt-3" style={{ margin: 0 }}>
                  Hi there, I am <b>Ankur Sanghvi</b>, Working as{" "}
                  <i>{currentCompany.designation}</i>{" "}
                  <a
                    href={currentCompany.link}
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ color: "var(--primary-color)" }}
                  >
                    <b>@{currentCompany.company}</b>
                  </a>{" "}
                  based in Bangalore, India.
                </p>
                <p className="desc mt-3" style={{ margin: 0 }}>
                  {" "}
                  I enjoy creating things that live on the internet, whether
                  that be websites, applications, or anything in between. My
                  goal is to build products that provide best web experiences.
                </p>
                <p className="desc mt-3" style={{ margin: 0 }}>
                  Apart from technical interest I also like to watch Action,
                  Adventure and most importantly Sci-fi movies & Web series.
                  Also a ANIME fan.
                </p>
                <p className="desc mt-3 mb-3" style={{margin: 0}}>
                  Love to spend quality time with my family and friends.
                  {/* And travel around with them. */}
                </p>
                <p className="desc special-text mt-5" style={{margin: 0}}>
                  {" "}
                  <b style={{ color: "var(--primary-color)" }}>Ankur Sanghvi</b>
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 my-image">
              <div className="request-loader">
                <img
                  className="img-fluid rounded-100 img-responsive mx-auto d-block"
                  src={"assets/images/my-image-min.jpg"}
                  alt="Ankur Sanghvi"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default About;
