import React, { Component } from "react";
import { Switch, Route, Redirect, Link, withRouter } from "react-router-dom";
import "./App.css";
import routes from "./routes";
import Header from "./Components/header";
import { isBlogActive, isMousetrapActive, showGithub } from "./config";

class App extends Component {
  componentDidMount() {
    const that = this;
    if (isMousetrapActive)
      window.Mousetrap.bind("t e c h b o t", function () {
        that.props.history.push("admin");
      });
  }
  componentWillUnmount() {
    if (isMousetrapActive) {
      window.Mousetrap.unbind("t e c h b o t");
      window.Mousetrap.reset();
    }
  }
  render() {
    return (
      <div id="wrapper" className="App">
        <div id="pageWrapper">
          <Header />
          {!!isBlogActive && (
            <div className="blog-link">
              <div className="triangle">
                <Link to="/blogs">
                  <span className="text">Blog</span>
                </Link>
              </div>
            </div>
          )}
          {!!showGithub && (
            <div className="github-link">
              <a
                target="_blank"
                className="d-block"
                rel="noopener noreferrer"
                href="https://github.com/techbot11"
              >
                <div className="triangle">
                  <span className="text">
                    <i className="fab fa-github"></i>
                  </span>
                </div>
              </a>
            </div>
          )}
          <Switch>
            {routes
              .filter((i) => i.isActive)
              .map((route, i) => (
                <Route
                  key={i}
                  component={route.component}
                  path={route.path}
                  exact={route.exact}
                />
              ))}
            <Redirect to="/" />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
