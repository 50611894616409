import React, { useEffect, useState } from "react";
import Loader from "../../Components/loader";
import ReactToPdf from "react-to-pdf";
import Timeline from "../../Components/timeline";
import ResumeData from "../../Assets/data/resume.json";

export default function Resume(props) {
  const [loader, setLoader] = useState(true);

  setTimeout(() => setLoader(false), 3000);

  useEffect(() => {}, []);

  if (loader) {
    return <Loader isloading={loader} />;
  }

  const { basic, work, education } = ResumeData;
  const BasicDetailSection = (
    <>
      {basic.designation && (
        <h4 style={styles.designation}>{basic.designation.toUpperCase()}</h4>
      )}
      {basic.fullName && <h1 style={styles.myName}>{basic.fullName}</h1>}
      <section
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 10,
          fontWeight: "bold",
        }}
      >
        {basic.contactNo && (
          <div style={{ marginRight: 15 }}>{basic.contactNo}</div>
        )}
        <div>
          {basic.email && <div>{basic.email}</div>}
          {basic.website && (
            <a style={{ color: "#5b5f68" }} href={basic.website}>
              {basic.website}
            </a>
          )}
        </div>
      </section>
    </>
  );

  return (
    <main id="mainWrapper" className="resume">
      <div
        id="pageBody"
        style={{
          alignItems: "start",
          fontFamily: "Segoe UI",
          letterSpacing: 1,
        }}
      >
        <div id="pageContent">
          <div className="row">
            <div className="col-xs-0 col-lg-2" />
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
              <ReactToPdf
                filename={`invoice.pdf`}
                options={{
                  unit: "mm",
                  format: [297, 210],
                  pageNumber: 2,
                }}
              >
                {({ toPdf, targetRef }) => (
                  <>
                    <div
                      className="card bg-card"
                      style={{
                        minHeight: "100vh",
                        height: "1122px",
                        border: 0,
                        flexDirection: "row",
                      }}
                      id={"print-resume"}
                      ref={targetRef}
                    >
                      <div className="text-white" style={styles.darkPart}>
                        <img
                          style={{ borderRadius: "100%", width: 150 }}
                          src={"assets/images/my-image-min.jpg"}
                          alt="pictures url"
                        />
                        <section style={{ marginTop: 30, width: "80%" }}>
                          <h5
                            style={{
                              fontWeight: "bold",
                              textAlign: "center",
                              width: "100%",
                              fontFamily: "Segoe UI",
                            }}
                          >
                            <i className={`fas fa-pencil-ruler fa-xs`} /> Skills
                          </h5>
                          <ul style={{ listStyleType: "disc" }}>
                            <li style={{ listStyle: "inherit" }}>Coffee</li>
                            <li style={{ listStyle: "inherit" }}>Tea</li>
                            <li style={{ listStyle: "inherit" }}>Milk</li>
                          </ul>
                        </section>
                      </div>
                      <div style={styles.whitePart}>
                        {BasicDetailSection}
                        {!!work.length && (
                          <section
                            style={{ marginTop: 20, textAlign: "center" }}
                          >
                            <div style={styles.sectionIcon}>
                              <i className={`fas fa-cog fa-xs`} />
                            </div>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                width: "35%",
                                fontFamily: "Segoe UI",
                              }}
                            >
                              EXPERIENCE
                            </h6>
                            <Timeline
                              data={work
                                .map((item) => ({
                                  title: item.company,
                                  subtext: `As a ${item.designation}`,
                                  body: item.keyPoints,
                                  date: item.exp,
                                }))
                                .reverse()}
                            />
                          </section>
                        )}
                        {!!education.length && (
                          <section
                            style={{
                              marginTop: 20,
                              textAlign: "center",
                              width: "100%",
                            }}
                          >
                            <div style={styles.sectionIcon}>
                              <i className={`fas fa-graduation-cap fa-xs`} />
                            </div>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                width: "35%",
                                fontFamily: "Segoe UI",
                              }}
                            >
                              Education
                            </h6>
                            <Timeline
                              data={education
                                .filter((i) => !!i.isShown)
                                .map((item) => ({
                                  title: item.degree,
                                  subtext: `${item.keyPoints[0]}`,
                                  date: item.exp,
                                }))
                                .reverse()}
                            />
                          </section>
                        )}
                      </div>
                    </div>
                    <button
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 20,
                        border: 0,
                        borderRadius: 4,
                        backgroundColor: "var(--primary-color)",
                        padding: 5,
                        width: 100,
                      }}
                      onClick={toPdf}
                    >
                      Print
                    </button>
                  </>
                )}
              </ReactToPdf>
            </div>
            <div className="col-xs-0 col-lg-2" />
          </div>
          <div className="row">
            <div className="col-xs-0 col-lg-2" />
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
              <ReactToPdf
                filename={`invoice.pdf`}
                options={{
                  unit: "mm",
                  format: [297, 210],
                  pageNumber: 2,
                }}
              >
                {({ toPdf, targetRef }) => (
                  <>
                    <div
                      className="card bg-card"
                      style={{
                        minHeight: "100vh",
                        height: "1122px",
                        border: 0,
                        flexDirection: "row",
                      }}
                      id={"print-resume"}
                      ref={targetRef}
                    >
                      <div className="text-white" style={styles.darkPart}>
                        <img
                          style={{ borderRadius: "100%", width: 150 }}
                          src={"assets/images/my-image-min.jpg"}
                          alt="pictures url"
                        />
                      </div>
                      <div style={styles.whitePart}>
                        {BasicDetailSection}
                        {!!ResumeData.awards.length && (
                          <section style={{ marginTop: 20 }}>
                            <div style={styles.sectionIcon}>
                              <i className={`fas fa-award fa-xs`} />
                            </div>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                width: "35%",
                                fontFamily: "Segoe UI",
                              }}
                            >
                              Awards
                            </h6>
                            <Timeline
                              data={ResumeData.awards
                                .filter((i) => !!i.isShown)
                                .map((item) => ({
                                  title: item.degree,
                                  subtext: item.rank,
                                  body: item.keyPoints,
                                  date: item.exp,
                                }))
                                .reverse()}
                            />
                          </section>
                        )}
                      </div>
                    </div>
                    <button
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 20,
                        border: 0,
                        borderRadius: 4,
                        backgroundColor: "var(--primary-color)",
                        padding: 5,
                        width: 100,
                      }}
                      onAbort
                      onClick={toPdf}
                    >
                      Print
                    </button>
                  </>
                )}
              </ReactToPdf>
            </div>
            <div className="col-xs-0 col-lg-2" />
          </div>
        </div>
      </div>
    </main>
  );
}

const styles = {
  darkPart: {
    backgroundColor: "#171717",
    padding: 50,
    minHeight: "100vh",
    width: "30%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  whitePart: {
    padding: "30px 15px",
    minHeight: "100vh",
    width: "70%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  designation: {
    fontFamily: "Segoe UI",
    fontWeight: "normal",
    letterSpacing: 5,
    marginBottom: 10,
  },
  myName: {
    fontFamily: "Segoe UI",
    fontWeight: 400,
    letterSpacing: 0,
    textTransform: "initial",
    color: "#171717",
  },
  sectionIcon: {
    backgroundColor: "#141414",
    textAlign: "center",
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "15%",
  },
};
