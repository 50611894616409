import React, { Component } from "react";
import "../skill-v2/skill-v2.css";
import Loader from "../../Components/loader";
import ResumeData from "../../Assets/data/resume.json";

class Experience extends Component {
  constructor(props) {
    super(props);
    const { work } = ResumeData;
    const workingAt = work.sort((a,b) => b.id - a.id);
    this.state = {
      loader: true,
      selectedTab: workingAt[0].company,
      workingAt,
    };
  }
  componentDidMount() {
    setTimeout(() => this.setState({ loader: false }), 3000);
  }

  componentWillUnmount() {}

  render() {
    const { loader, selectedTab, workingAt } = this.state;

    if (loader) {
      return <Loader isloading={loader} />;
    }

    return (
      <main id="mainWrapper" className="skill2">
        <div id="pageHead">
          <div className="page-header"></div>
        </div>
        <div id="pageBody" style={{ alignItems: "start" }}>
          <div id="pageContent">
            <div className="row">
              <div className="col-sm-10 start">
                <div className="col-sm-12">
                  <h1 className="h1 head">Professional History</h1>
                </div>
                <div className="col-sm-12 experience">
                  <ul
                    className="nav nav-tabs align-items-center justify-content-center"
                    id="myTab"
                    role="tablist"
                  >
                    {workingAt.map((item, index) => (
                      <li
                        key={index}
                        className="nav-item"
                        onClick={(e) =>
                          this.setState({ selectedTab: item.company })
                        }
                      >
                        <a
                          className={`nav-link ${
                            item.company === selectedTab ? "active" : ""
                          }`}
                          data-toggle="tab"
                          href={`#${item.id}`}
                          role="tab"
                          aria-controls={item.company}
                        >
                          {item.company}
                        </a>
                      </li>
                    ))}
                  </ul>

                  <div className="tab-content">
                    {workingAt.map((item, index) => (
                      <div
                        key={index}
                        className={`tab-pane ${
                          item.company === selectedTab ? "active" : ""
                        }`}
                        id={item.id}
                        role="tabpanel"
                      >
                        <div className="card text-white bg-dark bg-card animated fadeIn">
                          <div className="card-body">
                            <h5 className="card-title">
                              {/* {item.titles.join(' -> ')} */}
                              {item.designation}
                              {!!item.link ? (
                                <a
                                  href={item.link}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  <small className="card-subtitle text-muted">
                                    {" "}
                                    @{item.company}
                                  </small>
                                </a>
                              ) : (
                                <small className="card-subtitle text-muted">
                                  {" "}
                                  @{item.company}
                                </small>
                              )}
                            </h5>
                            <h6 className="card-subtitle mb-2 text-muted">
                              {item.exp}
                            </h6>
                            <ul className="key-points">
                              {item.keyPoints.map((point) => (
                                <li>{point}</li>
                              ))}
                            </ul>
                            {!!item.icon && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                  justifyContent: "flex-end",
                                  width: "100%",
                                }}
                              >
                                <img
                                  src={item.icon}
                                  width={50}
                                  alt="pictures url"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        {!!item.awards && (
                          <div className="card text-white bg-dark bg-card animated fadeIn">
                            <div className="card-body">
                              <div className="row">
                                {!!item.awards.length &&
                                  item.awards.map((pic) => (
                                    <div className="col-4">
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={pic.ImageUrl}
                                      >
                                        <img
                                          src={pic.ImageUrl}
                                          alt="pictures url"
                                        />
                                      </a>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {false && (
                <div className="col-sm-3 start">
                  <div className="col-sm-12">
                    <h1 className="h1 head">
                      <br />
                    </h1>
                  </div>
                  <div className="card text-white bg-dark bg-card animated fadeIn">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <h6>
                            Contributed in below{" "}
                            <b style={{ color: "var(--primary-color)" }}>
                              Projects
                            </b>
                            :
                          </h6>
                          {[1, 2, 3].map((item) => (
                            <div></div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Experience;
